
import { defineComponent, getCurrentInstance, onMounted, onUnmounted } from 'vue';
import BaseHeader from '@/components/layout/BaseHeader.vue'
import MessageModal from '@/components/modals/MessageModal.vue'

export default defineComponent({
  name: 'BaseLayout',
  components: {
    BaseHeader,
    MessageModal
  },
  setup() {
    // const appInstance = getCurrentInstance()

    // onMounted(() => {
    //   if (appInstance) {
    //     appInstance.appContext.config.globalProperties.$connectSocket()
    //   }
    // })
  }
})
